import logo from './logo.svg';
import './App.css';
import Main from './Component/Main'

import fond from "./assets/fond.png"

function App() {
  return (
    <div className="App" style={style}>
      <Main />
    </div>
  );
}

const style= {
  backgroundImage:`url(${fond})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100vw',
  height: '100vh',
  overflow: "hidden"
}

export default App;
