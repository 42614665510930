import React, { useState } from 'react';
import bulles from '../../assets/bulles.png'
import '../Main.css'

function Name({ data, teamName }) {

    const getPos = () => {
        if (data) {
            var i = -1;
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if (element.teamName === teamName) i = index;
            }
            const score = i>=0?data[i].score:0;
            return (
                <div className='subText' style={{ marginBottom: 8 }}>
                    Vous êtes {i+1===1?<span>1<sup>er</sup></span>:<span>{`${i+1}`}<sup>ème</sup></span>} et vous avez obtenu {score} point{score>1?"s":""}.
                </div>
            )
        }
    }

    return (
        <div>
            <div style={{ position: "absolute", width: "100%" }}>
                <div style={{ height: 220, position: "relative" }}>
                    <div style={{ height: "100%", position: "absolute" }}>
                        <img src={bulles} style={{ height: "100%", width: "auto" }} />
                    </div>
                    <div style={{ textAlign: "left", position: "absolute" }} className="Title CenteredQuestion">
                        Scores
                    </div>
                </div>
            </div>
            <div className='PodiumBlockWrapper'>
                <div className='PodiumWrapper'>
                    <div className='NumberPodium PodiumBlock'>
                        1
                    </div>
                    <div className='TextPodium PodiumBlock'>
                        {data && data[0] ? `${data[0].teamName} - ${data[0].score} point${data[0].score>1?"s":""}` : ""}
                    </div>
                </div>
                <div className='PodiumWrapper2 podiumStep'>
                    <div className='NumberPodium2 PodiumBlock'>
                        2
                    </div>
                    <div className='TextPodium2 PodiumBlock'>
                        {data && data[1] ? `${data[1].teamName} - ${data[1].score} point${data[1].score>1?"s":""}` : ""}
                    </div>
                </div>
                <div className='PodiumWrapper3 podiumStep'>
                    <div className='NumberPodium3 PodiumBlock'>
                        3
                    </div>
                    <div className='TextPodium3 PodiumBlock'>
                        {data && data[2] ? `${data[2].teamName} - ${data[2].score} point${data[2].score>1?"s":""}` : ""}
                    </div>
                </div>
            </div>
            <div style={{ position: "absolute", width: "100%", bottom: "18%" }}>
                {
                    getPos()
                }
            </div>
        </div>
    )
}

export default Name;