import React, { useState } from 'react';
import axios from "axios"
import { io } from 'socket.io-client';
import Cookies from 'js-cookie';

import Config from "./config"
import Name from "./Pages/Name"
import Question from "./Pages/Question"
import Annonce from "./Pages/Annonce"
import Podium from "./Pages/Podium"
import quiz from "./quiz"
import coloplast from "../assets/Coloplast-logo.png"

var socket = io(Config.api, {
    transports: ["polling", "websocket"],
    path:"/API/socket.io/"
});

if (typeof window !== 'undefined') {
    socket.emit("identify", "team")
}

function Main() {
    const [disconnected, setDisconnected] = useState(false);
    const [teamName, setTeamName] = useState("");
    const [q, setQ] = useState(0)
    const [start, setStart] = useState(false)
    const [result, setResult] = useState(false)
    const [countdown, setCountdown] = useState(0)
    const [responded, setResponded] = useState(false)
    const [dataResult, setDataResult] = useState([0,0,0,0])
    const [podium, setPodium] = useState([])
    const [lastSocketId, setLastSocketId] = useState("")

    React.useEffect(() => {
        if (socket.id !== lastSocketId) {
            console.log("New session",lastSocketId,"--",socket.id)
            setLastSocketId(socket.id)
            if (teamName) socket.emit("reconnect", teamName);
            else socket.emit("identify", "team");
        }
    },[socket.id])

    React.useEffect(() => {
        const verifySocketInterval = setInterval(() => {
            if (!socket.connected) {
                console.log("Disconnected")
                setDisconnected(true)
            }
            else setDisconnected(false)
        }, 2000)

        const CookieTeamName = Cookies.get('TeamName');
        if (CookieTeamName) {
            setTeamName(CookieTeamName);
            socket.emit("reconnect", CookieTeamName);
            socket.on("NoTeam", async function (question) {
                Cookies.remove('TeamName');
                setTeamName("");
            })
        }
        
        socket.on("setQuestion", async function (question) {
            setQ(question)
            setResult(false)
            setStart(false)
            setResponded(false)
        })
        socket.on("setStartQ", async function (t) {
            setStart(t)
            setCountdown(30)
        })
        socket.on("setResultQ", async function ({b, dataResult}) {
            setResult(b)
            setDataResult(dataResult)
        })
        socket.on("setTimer", async function (t) {
            setCountdown(t)
        })
        socket.on("setPodium", async function (t) {
            setPodium(t)
        })

        return () => clearInterval(verifySocketInterval)
    }, [])

    const sendName = (_name) => {
        if (_name && _name !== "") {
            setTeamName(_name);
            socket.emit("setTeam", _name)
            Cookies.set('TeamName', _name, { expires: 7 });
        }
    }

    const sendResponse = (_r) => {
        if (_r >= 0) {
            setResponded(true)
            socket.emit("response", _r)
        }
    }

    const Action = () => {
        if (q >= 0) {
            const current = quiz[q];
            switch (current.type) {
                case "annonce":
                    return <Annonce key={"Annonce"+q} text={current.text} />
                case "question":
                    return <Question key={"Question"+q} Countdown={countdown} Started={start} Q={current} sendResponse={sendResponse} sended={responded} Result={result} dataResult={dataResult} />
                case "podium":
                    return <Podium key={"Podium"+q} data={podium} teamName={teamName} />
                default:
                    return (<div>Erreur type</div>)
            }
        }
    }

    const LostConnection = () => {
        if (disconnected) {
            return (
                <div className='errorModal'>
                    Connexion perdue, veuillez rafraîchir la page.
                </div>
            )
        }
    }

    return (
        <div style={{width:"100%", height:"100%"}}>
            <div style={{ width: "90%", height: "100%", marginLeft: "auto", marginRight: "auto", position: "relative" }}>
                {
                    LostConnection()
                }
                {
                    teamName !== "" ? Action() : <Name sendName={sendName} />
                }
            </div>
            <div style={{ position: "absolute", width: 200, bottom: 15,left:15 }}>
                <img src={coloplast} style={{ width: "100%" }} />
            </div>
        </div>
    )
}

export default Main;