import React, { useState } from 'react';
import '../Main.css'
import bulles from '../../assets/bulles.png'

function Annonce({ text }) {
    return (
        <div className='Centered' style={{width:"80%"}}>
            <div style={{ height: 220, position: "relative" }}>
                <div style={{ height: "100%", position: "absolute" }}>
                    <img src={bulles} style={{ height: "100%", width: "auto" }} />
                </div>
                <div style={{ textAlign: "left", position: "absolute" }} className="Title Centered2">
                    {text}
                </div>
            </div>
        </div>
    )
}

export default Annonce;