import React, { useState } from 'react';
import Annonce from './Annonce'

import Quiz from '../quiz'
import '../Main.css'
import bulles from '../../assets/bulles.png'

function Question({ Countdown, Started, Q, sendResponse, sended, Result, dataResult }) {
    const [selected, setSelected] = useState(-1);

    const timer = () => {
        const c = 100 - ((Countdown * 100) / 30)
        return (
            <div style={{ position: "absolute", right: 15, top: 50 }}>
                <div style={{ backgroundColor: "#15a3ba", borderRadius: 100, width: 75, height: 75, position: "relative" }}>
                    <div style={{ background: `conic-gradient(transparent ${c}%, 0, #178498 )`, borderRadius: 100, width: 100, height: 100 }} className="CenteredQuestion">
                        <div style={{ position: "relative", height: "100%", width: "100%" }}>
                            <div className="textCountdown">
                                {Countdown}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const Quiz = () => {
        if (Result) {
            return (
                <div>
                    <div style={{ position: "absolute", width: "100%" }}>
                        <div style={{ height: 220, position: "relative" }}>
                            <div style={{ height: "100%", position: "absolute" }}>
                                <img src={bulles} style={{ height: "100%", width: "auto" }} />
                            </div>
                            <div style={{ textAlign: "left", position: "absolute" }} className="Title CenteredQuestion">
                                Q{Q.qNum} : {Q.text}
                            </div>
                        </div>
                    </div>
                    <div className='Centered'>
                        <div style={{ position: "relative" }}>
                            <div className="QWrapper">
                                <div className='QBlock2'>
                                    <div className={(Q.good === 0 ? "GoodAnswer" : "notSelected") + " QBlock"}>
                                        <div className='Qtext'>
                                            {Q.r[0]}
                                        </div>
                                        <div className="percentR">
                                            <div className='QPercentText'>
                                                {dataResult[0]}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='QBlock2'>
                                    <div className={(Q.good === 2 ? "GoodAnswer" : "notSelected") + " QBlock"}>
                                        <div className='Qtext'>
                                            {Q.r[2]}
                                        </div>
                                        <div className="percentR">
                                            <div className='QPercentText'>
                                                {dataResult[2]}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="QWrapper wrapperMargin">
                                <div className='QBlock2'>
                                    <div className={(Q.good === 1 ? "GoodAnswer" : "notSelected") + " QBlock"}>
                                        <div className='Qtext'>
                                            {Q.r[1]}
                                        </div>
                                        <div className="percentR">
                                            <div className='QPercentText'>
                                                {dataResult[1]}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='QBlock2'>
                                    <div className={(Q.good === 3 ? "GoodAnswer" : "notSelected") + " QBlock"}>
                                        <div className='Qtext'>
                                            {Q.r[3]}
                                        </div>
                                        <div className="percentR">
                                            <div className='QPercentText'>
                                                {dataResult[3]}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='CommentaireQ'>
                            {
                                Q.com&&Q.com!==""?Q.com:null
                            }
                        </div>
                    </div>
                </div>
            )
        }
        else {
            if (!sended) {
                return (
                    <div>
                        {timer()}
                        <div style={{ position: "absolute", width: "100%" }}>
                            <div style={{ height: 220, position: "relative" }}>
                                <div style={{ height: "100%", position: "absolute" }}>
                                    <img src={bulles} style={{ height: "100%", width: "auto" }} />
                                </div>
                                <div style={{ textAlign: "left", position: "absolute" }} className="Title CenteredQuestion">
                                    Q{Q.qNum} : {Q.text}
                                </div>
                            </div>
                        </div>
                        <div className='Centered'>
                            <div style={{ position: "relative" }}>
                                <div className="QWrapper">
                                    <div className='QBlock2'>
                                        <div className={(selected === 0 ? "selected" : "notSelected") + " QBlock"} onClick={() => setSelected(0)}>
                                            <div className='Qtext'>
                                                {Q.r[0]}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='QBlock2'>
                                        <div className={(selected === 2 ? "selected" : "notSelected") + " QBlock"} onClick={() => setSelected(2)}>
                                            <div className='Qtext'>
                                                {Q.r[2]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="QWrapper wrapperMargin">
                                    <div className='QBlock2'>
                                        <div className={(selected === 1 ? "selected" : "notSelected") + " QBlock"} onClick={() => setSelected(1)}>
                                            <div className='Qtext'>
                                                {Q.r[1]}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='QBlock2'>
                                        <div className={(selected === 3 ? "selected" : "notSelected") + " QBlock"} onClick={() => setSelected(3)}>
                                            <div className='Qtext'>
                                                {Q.r[3]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: "absolute", width: "100%", bottom: "18%" }}>
                            <div className='subText' style={{ marginBottom: 8 }}>
                                Veuillez choisir une réponse et valider avec ce bouton :
                            </div>
                            <div className='ButtonQ' onClick={() => sendResponse(selected)}>
                                VALIDER
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return <Annonce text={"Merci votre réponse a bien été prise en compte !"} />
            }
        }
    }

    return (
        <div>
            {
                Started ? Quiz() : <Annonce text={"Patience, les questions vont bientôt apparaitre !"} />
            }
        </div>
    )
}

export default Question;