import React, { useState } from 'react';
import bulles from '../../assets/bulles.png'
import '../Main.css'

function Name({ sendName }) {
    const [name, setName] = useState("");

    const validName = () => {
        sendName(name)
    }

    return (
        <div>
            <div style={{ position: "absolute", width: "100%" }}>
                <div style={{ height: 220, position: "relative" }}>
                    <div style={{ height: "100%", position: "absolute" }}>
                        <img src={bulles} style={{ height: "100%", width: "auto" }} />
                    </div>
                    <div style={{ textAlign: "left", position: "absolute" }} className="Title CenteredQuestion">
                        Choisissez le nom de votre équipe !
                    </div>
                </div>
            </div>
            <div className="Centered">
                <input name='TeamName' className='textName' type={"text"} placeholder={".............................."} value={name} onChange={(v) => setName(v.currentTarget.value)} />
            </div>
            <div style={{ position: "absolute", width: "100%", bottom: "18%" }}>
                <div className='subText' style={{ marginBottom: 8 }}>
                    Tapez votre nom puis confirmez en cliquant sur valider :
                </div>
                <div className='ButtonQ' onClick={() => validName()}>
                    VALIDER
                </div>
            </div>
        </div>
    )
}

export default Name;