const Config = {
    //api: "http://192.168.1.131:8129"
    //api: "http://192.168.1.24:8129"
    //api:"http://192.168.0.22:8129"
    //Prod \/
    //api:"http://192.168.121.2:8129"
    //Prod online \/
    //api: "http://10.0.0.5:8129"
    api: "https://coloquiz.henri8.com"
}

export default Config;