const q = [
    {
        type: `annonce`,
        text: `Quiz`,
    },
    {
        type: `question`,
        text: `Combien il y a de lettres dans le mot stomathérapie ?`,
        r: [
            `9`,
            `11`,
            `13`,
            `15`
        ],
        good: 2,
        qNum: 0,
        dummy:true,
    },
    {
        type: `annonce`,
        text: `Manche 1`,
    },
    {
        type: `question`,
        text: `Quel est le nom de la première infirmière française diplômée en stomathérapie ?`,
        r: [
            `Suzanne Montandon`,
            `Norma Gill`,
            `Elise Sorensen`,
            `Johanne Curie`
        ],
        good: 0,
        qNum: 1,
    },
    {
        type: `question`,
        text: `Quel scientifique aurait reçu une pomme sur la tête avant de théoriser la loi relative à la gravitation universelle ?`,
        r: [
            `Archimède`,
            `Isaac Newton`,
            `Nicolas Copernic`,
            `Alexandre Fleming`
        ],
        good: 1,
        qNum: 2,
    },
    {
        type: `question`,
        text: `Quelle est la date de création de Coloplast ?`,
        r: [
            `1933`,
            `1927`,
            `1942`,
            `1957`
        ],
        good: 3,
        qNum: 3,
    },
    {
        type: `question`,
        text: `Qui fut la première femme de l’Histoire à recevoir un prix Nobel ?`,
        r: [
            `Marie Curie${'\n'}(prix Nobel de physique)`,
            `Maria Montessori${'\n'}(prix Nobel de la Paix)`,
            `Irène Joliot-Curie${'\n'}(prix Nobel Chimie)`,
            `Nadine Gordimer${'\n'}(prix Nobel de Littérature)`
        ],
        good: 0,
        qNum: 4,
        com: "Pour ses recherches sur la radioactivité en 1903.",
    },
    {
        type: `question`,
        text: `A quelle date a lieu la journée nationale de la stomie ?`,
        r: [
            `3 février`,
            `3 mai`,
            `3 septembre`,
            `3 décembre`
        ],
        good: 1,
        qNum: 5,
    },
    {
        type: `podium`,
        text: `Manche 1`,
    },
    {
        type: `annonce`,
        text: `Manche 2`,
    },
    {
        type: `question`,
        text: `Quel scientifique a découvert le vaccin contre la rage ?`,
        r: [
            `Antoine Laurent Lavoisier`,
            `Louis Pasteur`,
            `Pierre Bretonneau`,
            `Pierre Curie`
        ],
        good: 1,
        qNum: 6,
        com:`Créé en 1885 dans le but de traiter la maladie mortelle de la rage.${'\n'}Il est testé la 1ère fois chez l’homme sur un jeune enfant mordu par un chien enragé`
    },
    {
        type: `question`,
        text: `Dans quelle ville a été ouverte la première consultation de stomathérapie en France ?`,
        r: [
            `Lyon`,
            `Paris`,
            `Nantes`,
            `Limoges`
        ],
        good: 0,
        qNum: 7,
    },
    {
        type: `question`,
        text: `En biologie, quel est le nom complet de l’ADN contenant notre information génétique ?`,
        r: [
            `Antigène digitalonatalien`,
            `Acide désoxyribonucléique`,
            `Analyse déphragmenutritive`,
            `Akène distilonatural`
        ],
        good: 1,
        qNum: 8,
        com: "L'ADN contient toute l'information génétique, appelée génome, permettant le développement, le fonctionnement et la reproduction des êtres vivants."
    },
    {
        type: `question`,
        text: `D’après les dernières données disponibles, quel est l’âge moyen des patients lors de la chirurgie de réalisation de la stomie ?`,
        r: [
            `65 ans`,
            `68 ans`,
            `71 ans`,
            `74 ans`
        ],
        good: 1,
        qNum: 9,
    },
    {
        type: `question`,
        text: `Comment s’appelle la technologie unique au protecteur cutané Sensura Mio ?`,
        r: [
            `BodyTouch`,
            `BodyFit`,
            `SuperFit`,
            `SuperPlus`
        ],
        good: 1,
        qNum: 10,
    },
    {
        type: `podium`,
        text: `Manche 2`,
    },
    {
        type: `annonce`,
        text: `Dernière manche`,
    },
    {
        type: `question`,
        text: `De combien de collaborateurs dans le monde est composé Coloplast ?`,
        r: [
            `15900 `,
            `11200`,
            `9800`,
            `24000`
        ],
        good: 0,
        qNum: 11,
    },
    {
        type: `question`,
        text: `Lequel de ces aliments est considéré comme un ralentisseur de transit ?`,
        r: [
            `Crudités`,
            `Banane`,
            `Glace`,
            `Thé`
        ],
        good: 1,
        qNum: 12,
    },
    {
        type: `question`,
        text: `Où sont produites la majorité des références Sensura Mio ?`,
        r: [
            `En Allemagne`,
            `En Hongrie`,
            `En Italie`,
            `En Espagne`
        ],
        good: 1,
        qNum: 13,
    },
    {
        type: `question`,
        text: `Quand a été recensée la première stomie dans le Monde ?`,
        r: [
            `20ème siècle`,
            `19ème siècle`,
            `18ème siècle`,
            `17ème siècle`
        ],
        good: 2,
        qNum: 14,
    },
    {
        type: `question`,
        text: `Quel chirurgien réalisa la première transplantation cardiaque ?`,
        r: [
            `Christian Barnard`,
            `James Hardy`,
            `Norman Shumway`,
            `Sebastian Grecy`
        ],
        good: 0,
        qNum: 15,
        com:`La première transplantation cardiaque utilisant un cœur humain a été faite par Christiaan Barnard,${'\n'}chirurgien cardiaque au Cap en Afrique du Sud, le 3 décembre 1967.${'\n'}Il est suivi par Norman Shumway aux États-Unis quelques mois plus tard`
    },
    {
        type: `podium`,
        text: `Dernière manche`,
    },
]
export default q;